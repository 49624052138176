'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { signIn } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import cloudflareLoader from '~/imageLoader';
import { ShowPasswordButton } from './ShowPasswordButton';
import { signInSchema, SignInType } from './schemas';

const SignIn: React.FC = () => {
  const t = useTranslations();

  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get('callbackUrl') || '/';

  const errorType = searchParams.get('error') || '';
  const errorMessages: Record<string, string> = {
    CredentialsSignin: t('signIn.errorCredentials'),
    default: t('signIn.errorDefault'),
  };

  const [error, setError] = useState<string>(errorMessages[errorType] || '');

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isLoading, isValidating },
  } = useForm<SignInType>({
    resolver: zodResolver(signInSchema),
  });

  console.log('Callback URL: ', callbackUrl);

  const onSubmit: SubmitHandler<SignInType> = async (data) => {
    setError('');
    await signIn('credentials', {
      redirect: true,
      username: data.username,
      password: data.password,
      callbackUrl,
    });
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <div className="relative w-full max-w-sm rounded-lg bg-white p-8 shadow-lg sm:max-w-[425px]">
        <div className="flex justify-center">
          <Image
            loader={cloudflareLoader}
            src="https://assets.tiketti.fi/production/img/2021/tiketti_logo.svg"
            alt="Tiketti Logo"
            width="158"
            height="158"
            className="h-[158px] w-[158px]"
            priority
          />
        </div>
        <div className="pb-8 pt-6">
          {error && <p className="mb-4 text-center text-sm text-red-500">{error}</p>}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                {t('signIn.username')}
              </label>
              <input
                id="username"
                type="text"
                disabled={isSubmitting}
                {...register('username')}
                className="focus:ring-brand-yellow-100 shadow-2xs focus:outline-hidden mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:ring-1 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500 sm:text-sm"
              />
              {errors.username && <p className="text-sm text-red-500">{errors.username.message}</p>}
            </div>

            <div className="relative mb-4 w-full">
              <label htmlFor="password" className="text-sm font-medium text-gray-700">
                {t('signIn.password')}
              </label>
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                disabled={isSubmitting}
                {...register('password')}
                className="focus:ring-brand-yellow-100 shadow-2xs focus:outline-hidden w-full rounded-md border border-gray-300 pr-10 focus:ring-1 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500 sm:text-sm"
              />
              {errors.password && <p className="text-sm text-red-500">{errors.password.message}</p>}
              <ShowPasswordButton showPassword={showPassword} setShowPassword={setShowPassword} />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-brand-yellow-100 focus:ring-brand-yellow-100 shadow-2xs focus:outline-hidden w-full rounded-md px-4 py-2 font-bold uppercase text-white hover:bg-yellow-500 focus:ring-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-600"
            >
              {isSubmitting ? t('signIn.signInButtonSubmitting') : t('signIn.signInButton')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
