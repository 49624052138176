import z from 'zod';

//const passwordValidation = new RegExp(/^(?=.*?[a-z])(?=.*?[0-9]).{6,}$/);

const passwordSignInError = 'Incorrect credentials!';

export const signInSchema = z.object({
  username: z.string(),
  password: z.string().min(6, passwordSignInError).max(255, passwordSignInError),
  //.regex(passwordValidation, passwordSignInError),
});
export type SignInType = z.infer<typeof signInSchema>;
