'use client';

import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import { EyeClosedIcon, EyeOpenIcon } from '@radix-ui/react-icons';

interface ShowPasswordButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  showPassword: boolean;
  setShowPassword: Dispatch<SetStateAction<boolean>>;
}

export const ShowPasswordButton = (props: Omit<ShowPasswordButtonProps, 'children'>) => {
  const { showPassword, setShowPassword } = props;
  return (
    <button
      type="button"
      onClick={() => setShowPassword((prev) => !prev)}
      className="absolute inset-y-8 right-2 cursor-pointer"
    >
      {showPassword ? (
        <EyeOpenIcon className="w-6 h-6 text-gray-500" />
      ) : (
        <EyeClosedIcon className="w-6 h-6 text-gray-500" />
      )}
    </button>
  );
};
